@charset "utf-8";

/* CSS Document */

/*

 Template Name : Borrow - A Loan Company Website Template
 Author : Jitu Chauhan;
 Version: 1.0.0;

+ General css 
+ Space css
+ Background & Block color 
+ Buttons
+ Input Form
+ Bullet
+ Icon
+ Navigation CSS

+ HOME
  - Home
  - Home-1
  - Home-2
+ LOAN PRODUCT
  - Loan Image Listing
  - Loan Icon Listing
  - Car Loan Single
  - Personal Loan Single
  - Home Loan Single
  - Education Loan Single
+ ABOUT US 
  - About us
  - Team
+ BLOG
  - Blog Listing
  - Blog Single
  - Two Column Masonry
  - Three Column Masonry
+ FEATURES
  - Compare Loan
  - Faq page
  - Testimonial
  - 404 Error
    - Gallery
      - Filterable Gallery 2 column
      - Filterable Gallery 3 column
      - Masonry Gallery
      - Zoom Gallery
    - Shortcodes
      - Tab
      - Accordion
      - Alert       

- CONTACT US
    
*/

/*-----------------------------------
General css class
-----------------------------------*/

body {
    font-size: 15px;
    font-family: Arial, sans-serif !important;
    line-height: 1.4;
    color: #778191;
    background-color: #f4f6f8;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0px 0px 10px 0px;
    color: #3c4d6b;
    font-weight: 500;
}

h1 {
    font-size: 32px;
    line-height: 44px;
    letter-spacing: -1px;
}

h2 {
    font-size: 24px;
    line-height: 32px;
}

h3 {
    font-size: 20px;
}

h4 {
    font-size: 16px;
    line-height: 1.6;
}

p {
    margin: 0 0 26px;
    line-height: 1.6;
    font-weight: 400;
}

ul, ol {
}

ul li, ol li {
    line-height: 1.8
}

a {
    color: #f51f8a;
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

a:focus, a:hover {
    text-decoration: none;
    color: #15549a;
}

strong {
    font-weight: 600;
}

blockquote {
    padding: 10px 20px;
    margin: 0 0 0px;
    font-size: 21px;
    border-left: 5px solid #eff1f4;
    color: #414346;
    font-style: italic;
    line-height: 35px;
    font-family: 'Merriweather', serif;
    font-weight: 700;
}

blockquote.alignright {
    max-width: 50%;
}

blockquote.alignleft {
    max-width: 50%;
}

p:last-child {
    margin-bottom: 0px;
}

::selection {
    background: #ffecb3;
    color: #333;
}

*, *::before, *::after {
    transition: all .3s ease-in-out;
}

.text-highlight {
    color: #ffecb3;
}

.highlight {
    color: #f51f8a;
}

.italic {
}

.small-text {
}

.listnone {
    list-style: none;
    margin: 0px 0px 20px 0px;
    padding: 0;
}

.required {
    color: red
}

.lead {
    color: #3c4d6b;
    line-height: 36px;
    font-size: 24px;
}

.lead-strong {
}

.section-title {
}

.text-white {
    color: #fff;
}

.title-white {
    color: #fff;
}

.title {
    color: #3c4d6b;
}

/*-----------------------------------
Space css class
-----------------------------------*/

/*----------------
margin space
-----------------*/

.mb0 {
    margin-bottom: 0px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb20 {
    margin-bottom: 20px;
}

.mb30 {
    margin-bottom: 30px;
}

.mb40 {
    margin-bottom: 40px;
}

.mb60 {
    margin-bottom: 60px;
}

.mb80 {
    margin-bottom: 80px;
}

.mb100 {
    margin-bottom: 100px;
}

.mt0 {
    margin-top: 0px;
}

.mt10 {
    margin-top: 10px;
}

.mt20 {
    margin-top: 20px;
}

.mt30 {
    margin-top: 30px;
}

.mt40 {
    margin-top: 40px;
}

.mt60 {
    margin-top: 60px;
}

.mt80 {
    margin-top: 80px;
}

.mt100 {
    margin-top: 100px;
}

/*----------------
less margin space from top
-----------------*/
.mt-20 {
    margin-top: -20px;
}

.mt-40 {
    margin-top: -40px;
}

.mt-60 {
    margin-top: -60px;
}

.mt-80 {
    margin-top: -80px;
}

/*---------------
Padding space
-----------------*/
.nopadding {
    padding: 0px;
}

.nopr {
    padding-right: 0px;
}

.nopl {
    padding-left: 0px;
}

.pinside10 {
    padding: 10px;
}

.pinside20 {
    padding: 20px;
}

.pinside30 {
    padding: 30px;
}

.pinside40 {
    padding: 40px;
}

.pinside50 {
    padding: 50px;
}

.pinside60 {
    padding: 60px;
}

.pinside80 {
    padding: 80px;
}

.pdt10 {
    padding-top: 10px;
}

.pdt20 {
    padding-top: 20px;
}

.pdt30 {
    padding-top: 30px;
}

.pdt40 {
    padding-top: 40px;
}

.pdt60 {
    padding-top: 60px;
}

.pdt80 {
    padding-top: 80px;
}

.pdb10 {
    padding-bottom: 10px;
}

.pdb20 {
    padding-bottom: 20px;
}

.pdb30 {
    padding-bottom: 30px;
}

.pdb40 {
    padding-bottom: 40px;
}

.pdb60 {
    padding-bottom: 60px;
}

.pdb80 {
    padding-bottom: 80px;
}

.pdl10 {
    padding-left: 10px;
}

.pdl20 {
    padding-left: 20px;
}

.pdl30 {
    padding-left: 30px;
}

.pdl40 {
    padding-left: 40px;
}

.pdl60 {
    padding-left: 60px;
}

.pdl80 {
    padding-left: 80px;
}

.pdr10 {
    padding-right: 10px;
}

.pdr20 {
    padding-right: 20px;
}

.pdr30 {
    padding-right: 30px;
}

.pdr40 {
    padding-right: 40px;
}

.pdr60 {
    padding-right: 60px;
}

.pdr80 {
    padding-right: 80px;
}

.x {
}

.y {
    padding-top: 220px;
}

/*---------------
Section Space
* Section space help to create top bottom space.
*
-----------------*/
.section-space20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.section-space40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.section-space60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.section-space80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.section-space100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.section-space120 {
    padding-top: 120px;
    padding-bottom: 120px;
}

.section-space140 {
    padding-top: 140px;
    padding-bottom: 140px;
}

/*-----------------------------------------
Background & Block color
------------------------------------------*/
.bg-light {
    background-color: #fafcfd;
}

.bg-primary {
    background-color: #eff1f4;
    color: #778191;
}

.bg-default {
    background-color: #15549a;
    color: #87c0ff;
}

.bg-white {
    background-color: #fff;
}

.outline {
    border: 1px solid #dee4ee;
}

.link-title {
}

.small-title {
    font-size: 14px;
    color: #778191;
    font-weight: 500;
}

.big-title {
    font-size: 36px;
    font-weight: 600;
    color: #15549a;
}

.capital-title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
}

.bdr-btm {
}

.green-text {
}

.bg-boxshadow {
    -webkit-box-shadow: 0px 4px 16px 3px rgba(213, 221, 234, .5);
    -moz-box-shadow: 0px 4px 16px 3px rgba(213, 221, 234, .5);
    box-shadow: 0px 4px 16px 3px rgba(213, 221, 234, .5);
}

.bg-gradient {
    position: relative; /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1f2b70+0,1f2b70+47,943a9e+100&0.9+0,0.9+100 */
    background: -moz-linear-gradient(-45deg, rgba(31, 43, 112, 1) 0%, rgba(31, 43, 112, 1) 47%, rgba(148, 58, 158, 1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(31, 43, 112, 0.9) 0%, rgba(31, 43, 112, 0.9) 47%, rgba(148, 58, 158, 0.9) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(31, 43, 112, 0.9) 0%, rgba(31, 43, 112, 0.9) 47%, rgba(148, 58, 158, 0.9) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e61f2b70', endColorstr='#e6943a9e', GradientType=1); /* IE6-9 fallback on horizontal gradient */
}

.well-box {
    background-color: #fff;
    padding: 30px;
    border-radius: 4px;
}

.contact-box {
    min-height: 355px;
}

/* Update 16 june 2017*/

/*-----------------------------------------
Buttons
------------------------------------------*/
.btn {
    font-size: 14px;
    border-radius: 2px;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 20px 30px;
    border: none;
    font-weight: 600;
}

.btn-default {
    background-color: #f51f8a;
    color: #fff;
    border-color: #f51f8a;
}

.btn-default:hover {
    color: #fff;
    background-color: #ff389c;
    border-color: #ff389c;
}

.btn-default.focus, .btn-default:focus {
    color: #fff;
    background-color: #d5504f;
    border-color: #c64241;
}

.btn-primary {
    background-color: #15549a;
    color: #fff;
}

.btn-primary:hover {
    color: #fff;
    background-color: #2573cb;
}

.btn-primary.focus, .btn-primary:focus {
}

.btn-secondary {
    background-color: #15549a;
    color: #fff;
}

.btn-secondary:hover {
    background-color: #f51f8a;
    color: #fff;
}

.btn-outline {
    border: 2px solid #dee4ee;
    color: #9aa9c3;
    background-color: transparent;
    padding: 17px 28px;
}

.btn-outline:hover {
    border: 2px solid #dee4ee;
    color: #6a7a94;
    background-color: #dee4ee;
}

.btn-lg {
    padding: 25px 50px;
    font-size: 18px;
    text-align: center;
}

.btn-sm {
    padding: 15px 20px;
    font-size: 12px;
    text-align: center;
}

.btn-xs {
    padding: 4px 8px;
    font-size: 10px;
}

.btn-link {
    color: #15549a;
    font-weight: 500;
    padding-bottom: 4px;
    border-bottom: 2px solid #15549a;
    display: inline-block;
}

.btn-link:hover {
    color: #f51f8a;
    font-weight: 500;
    padding-bottom: 4px;
    border-bottom: 2px solid #f51f8a;
    text-decoration: none;
}

.btn-link-white {
}

.btn-link-white:hover {
}

.btn-link-orange {
}

.btn-link-white:hover {
}

/*-----------------------------------------
Input Form
------------------------------------------*/
label {
}

.control-label {
    text-transform: uppercase;
    color: #3c4d6b;
    font-size: 13px;
    font-weight: 600;
}

.form-control {
    width: 100%;
    height: 52px;
    padding: 6px 16px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-image: none;
    background-color: #fff;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, .075);
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, .075);
    margin-bottom: 10px;
    border: 2px solid #e6ecef;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: #fff;
}

.form-control:focus {
}

.required {
    color: red
}

textarea.form-control {
    border-radius: 2px;
    height: auto;
    min-width: 100%;
    max-width: 100%;
}

select {
    -webkit-appearance: none;
    background-color: #fff;
}

/*-----------------------------------------
content
------------------------------------------*/
.content {
    padding-bottom: 100px;
    padding-top: 100px;
}

.content-area {
}

.sidebar-area {
}

.wrapper-content {
    margin-top: -40px;
    margin-bottom: 100px;
    position: relative;
}

/*-----------------------------------------
Bullet
------------------------------------------*/
.bullet {
    position: relative;
    padding-left: 25px;
    line-height: 32px;
}

.bullet-arrow-circle-right li:before {
    font-family: 'FontAwesome';
    display: block;
    position: absolute;
    left: 0;
    font-size: 15px;
    color: #919297;
    content: "\f18e";
}

.bullet-arrow-right li:before {
    font-family: 'FontAwesome';
    display: block;
    position: absolute;
    left: 0;
    font-size: 15px;
    color: #747474;
    content: "\f101";
}

.bullet-check-circle li:before {
    font-family: 'FontAwesome';
    display: block;
    position: absolute;
    left: 0;
    font-size: 15px;
    color: #fff;
    content: "\f05d";
}

.bullet-check-circle-default li:before {
    font-family: 'FontAwesome';
    display: block;
    position: absolute;
    left: 0;
    font-size: 15px;
    color: #10a874;
    content: "\f05d";
    padding-right: 4px;
}

/* update 16 june 2017 */
.list-pdf-file li:before {
    font-family: 'FontAwesome';
    display: block;
    position: absolute;
    left: 0;
    font-size: 15px;
    color: #ec4e10;
    content: "\f1c1";
    padding-right: 4px;
}

.list-pdf-file li a {
    color: #104b8c;
    text-decoration: underline;
    font-weight: 500;
}

.list-pdf-file li a:hover {
    color: #f51f8a;
}

/*-----------------------------------------
icon
------------------------------------------*/
.icon {
}

.icon-1x {
    font-size: 24px;
}

/* default icon size of font*/
.icon-2x {
    font-size: 48px;
}

.icon-4x {
    font-size: 68px;
}

.icon-6x {
    font-size: 88px;
}

.icon-8x {
    font-size: 108px;
}

.icon-default {
    color: #f51f8a;
}

.icon-primary {
    color: #15549a;
}

.icon-secondary {
    color: #bcbcbc;
}

.dark-line {
    border-top: 2px solid #2b2e35;
}

/*-------- updated v 1.0.2 -------*/
.fa-primary {
}

.fa-default {
    color: #f51f8a;
}

.fa-success {
    color: #6eb232;
}

/*-----------------------------------------
top bar
------------------------------------------*/
.top-bar {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #15549a;
    font-size: 12px;
    color: #83bcfa;
    font-weight: 500;
    text-transform: uppercase;
}

.top-text {
    padding-left: 20px;
    line-height: 1.6;
}

.top-text a {
    color: #add4ff;
}

.top-text.active a {
    color: #fff;
}

.top-text a:hover {
    color: #fff;
}

.search-nav {
    margin-top: 10px;
    text-align: center;
}

.search-btn {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 5px 0px 0px 0px;
    margin-top: 7px;
    color: #7e93ae;
    background-color: #eff3f8;
    display: inline-block;
}

.searchbar {
}

.search-area {
    padding: 30px;
}

.header {
    background-color: #fff;
    padding-top: 24px;
    padding-bottom: 24px;
    position: relative;
    z-index: 99;
    -webkit-box-shadow: 0px 5px 9px 1px rgba(144, 161, 180, 0.2);
    box-shadow: 0px 5px 9px 1px rgba(144, 161, 180, 0.2);
    -moz-box-shadow: 0px 5px 9px 1px rgba(144, 161, 180, 0.2);
}

.logo img {
    height: 53px;
}

.navigation-wide {
    background-color: #15549a
}

.navigation-wide #navigation {
    margin-top: 0px;
    float: left;
    background-color: #15549a;
}

#navigation, #navigation ul, #navigation ul li, #navigation ul li a, #navigation #menu-button {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none;
    line-height: 1;
    display: block;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

#navigation:after, #navigation > ul:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

#navigation #menu-button {
    display: none;
}

#navigation {
    float: right;
    margin-top: 8px;
    position: relative;
    z-index: 99;
}

#navigation > ul > li {
    float: left;
}

#navigation.align-center > ul {
    font-size: 0;
    text-align: center;
}

#navigation.align-center > ul > li {
    display: inline-block;
    float: none;
}

#navigation.align-center ul ul {
    text-align: left;
}

#navigation.align-right > ul > li {
    float: right;
}

#navigation > ul > li > a {
    padding: 16px 10px;
    font-size: 13px;
    text-decoration: none;
    color: #66707f;
    font-weight: 600;
    text-transform: uppercase;
}

#navigation > ul > li:hover > a {
    color: #f51f8a;
}

#navigation > ul > li.has-sub > a {
    padding-right: 30px;
}

/*#navigation > ul > li.has-sub > a:after {position: absolute; top: 22px; right: 11px; width: 8px; height: 2px;
display: block; background: #dddddd; content: '';}*/
#navigation > ul > li.has-sub > a:before {
    position: absolute;
    right: 14px;
    font-family: FontAwesome;
    content: "\f107";
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}

#navigation ul ul li a .badge {
    font-size: 10px;
    background: #f51f8a;
    padding: 1px 7px;
}

/*#navigation > ul > li.has-sub:hover > a:before {top: 23px; height: 0;}*/
#navigation ul ul {
    position: absolute;
    left: -9999px;
}

#navigation.align-right ul ul {
    text-align: right;
}

#navigation ul ul li {
    height: 0;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}

#navigation li:hover > ul {
    left: auto;
}

#navigation.align-right li:hover > ul {
    left: auto;
    right: 0;
}

#navigation li:hover > ul > li {
    height: 45px;
}

#navigation ul ul ul {
    margin-left: 100%;
    top: 0;
}

#navigation.align-right ul ul ul {
    margin-left: 0;
    margin-right: 100%;
}

#navigation ul ul li a {
    border-bottom: 1px solid rgba(222, 231, 242, 1);
    padding: 15px 15px 16px 15px;
    width: 220px;
    font-size: 13px;
    text-decoration: none;
    color: #66707f;
    font-weight: 500;
    background: #fff;
}

#navigation ul ul li:last-child > a, .navigation ul ul li.last-item > a {
    border-bottom: 0;
}

#navigation ul ul li:hover > a, #navigation ul ul li a:hover {
    color: #f51f8a;
    background-color: #f4f6f8;
}

#navigation ul ul li.has-sub > a:after {
    position: absolute;
    top: 20px;
    right: 11px;
    width: 8px;
    height: 2px;
    display: block;
    background: #66707f;
    content: '';
}

#navigation.align-right ul ul li.has-sub > a:after {
    right: auto;
    left: 11px;
}

#navigation ul ul li.has-sub > a:before {
    position: absolute;
    top: 17px;
    right: 14px;
    display: block;
    width: 2px;
    height: 8px;
    background: #66707f;
    content: '';
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}

#navigation.align-right ul ul li.has-sub > a:before {
    right: auto;
    left: 14px;
}

#navigation ul ul > li.has-sub:hover > a:before {
    top: 17px;
    height: 0;
}

#navigation.small-screen {
    width: 100%;
}

#navigation.small-screen ul {
    width: 100%;
    display: none;
}

#navigation.small-screen.align-center > ul {
    text-align: left;
}

#navigation.small-screen ul li {
    width: 100%;
    border-top: 1px solid rgba(223, 229, 237, 0.9);
}

#navigation.small-screen ul ul li, #navigation.small-screen li:hover > ul > li {
    height: auto;
}

#navigation.small-screen ul li a, #navigation.small-screen ul ul li a {
    width: 100%;
    border-bottom: 0;
}

#navigation.small-screen > ul > li {
    float: none;
}

#navigation.small-screen ul ul li a {
    padding-left: 25px;
}

#navigation.small-screen ul ul ul li a {
    padding-left: 35px;
}

#navigation.small-screen ul ul li a {
    color: #66707f;
    background: none;
}

#navigation.small-screen ul ul li:hover > a, #navigation.small-screen ul ul li.active > a {
    color: #f51f8a;
}

#navigation.small-screen ul ul, #navigation.small-screen ul ul ul, #navigation.small-screen.align-right ul ul {
    position: relative;
    left: 0;
    width: 100%;
    margin: 0;
    text-align: left;
}

#navigation.small-screen > ul > li.has-sub > a:after, #navigation.small-screen > ul > li.has-sub > a:before, #navigation.small-screen ul ul > li.has-sub > a:after, #navigation.small-screen ul ul > li.has-sub > a:before {
    display: none;
}

#navigation.small-screen #menu-button {
    display: block;
    padding: 16px 0px;
    color: #f51f8a;
    cursor: pointer;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    border-top: 1px solid #e2e7ee
}

#navigation.small-screen #menu-button:after {
    position: absolute;
    top: 22px;
    right: 0px;
    display: block;
    height: 8px;
    width: 20px;
    border-top: 2px solid #f51f8a;
    border-bottom: 2px solid #f51f8a;
    content: '';
}

#navigation.small-screen #menu-button:before {
    position: absolute;
    top: 16px;
    right: 0px;
    display: block;
    height: 2px;
    width: 20px;
    background: #f51f8a;
    content: '';
}

#navigation.small-screen #menu-button.menu-opened:after {
    top: 23px;
    border: 0;
    height: 2px;
    width: 15px;
    background: #333;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#navigation.small-screen #menu-button.menu-opened:before {
    top: 23px;
    background: #333;
    width: 15px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#navigation.small-screen .submenu-button {
    position: absolute;
    z-index: 99;
    right: 0;
    top: 0;
    display: block;
    border-left: 1px solid rgba(223, 229, 237, 0.9);
    height: 46px;
    width: 46px;
    cursor: pointer;
}

#navigation.small-screen .submenu-button.submenu-opened {
    background: #15549a;
}

#navigation.small-screen ul ul .submenu-button {
    height: 34px;
    width: 34px;
}

#navigation.small-screen .submenu-button:after {
    position: absolute;
    top: 22px;
    right: 19px;
    width: 8px;
    height: 2px;
    display: block;
    background: #bcc6d3;
    content: '';
}

#navigation.small-screen ul ul .submenu-button:after {
    top: 15px;
    right: 13px;
}

#navigation.small-screen .submenu-button.submenu-opened:after {
    background: #ffffff;
}

#navigation.small-screen .submenu-button:before {
    position: absolute;
    top: 19px;
    right: 22px;
    display: block;
    width: 2px;
    height: 8px;
    background: #bcc6d3;
    content: '';
}

#navigation.small-screen ul ul .submenu-button:before {
    top: 12px;
    right: 16px;
}

#navigation.small-screen .submenu-button.submenu-opened:before {
    display: none;
}

#navigation.small-screen.select-list {
    padding: 5px;
}

#sub-nav {
    position: relative;
}

#sub-nav #menu-button {
    display: none;
}

#sub-nav.small-screen #menu-button {
    display: block;
    padding: 16px;
    color: #f51f8a;
    cursor: pointer;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    border-top: 1px solid #e2e7ee
}

#sub-nav.small-screen #menu-button:after {
    position: absolute;
    top: 22px;
    right: 10px;
    display: block;
    height: 8px;
    width: 20px;
    border-top: 2px solid #f51f8a;
    border-bottom: 2px solid #f51f8a;
    content: '';
}

#sub-nav.small-screen #menu-button:before {
    position: absolute;
    top: 16px;
    right: 10px;
    display: block;
    height: 2px;
    width: 20px;
    background: #f51f8a;
    content: '';
}

#sub-nav.small-screen #menu-button.menu-opened:before {
    top: 23px;
    background: #333;
    width: 15px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#sub-nav.small-screen #menu-button.menu-opened:after {
    top: 23px;
    border: 0;
    height: 2px;
    width: 15px;
    background: #333;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

/*-----------------------------------------
     Slider
------------------------------------------*/
#slider {
}

.slider {
    position: relative;
}

.slider-img img {
    position: relative;
    z-index: -1;
    width: 100%;
}

.slider-img {
    background: -moz-linear-gradient(top, rgba(16, 75, 149, 0.8) 0%, rgba(16, 75, 149, 0.8) 100%);
    background: -webkit-linear-gradient(top, rgba(16, 75, 149, 0.8) 0%, rgba(16, 75, 149, 0.8) 100%);
    background: linear-gradient(to bottom, rgba(16, 75, 149, 0.8) 0%, rgba(16, 75, 149, 0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cc1b2429', endColorstr='#cc1b2429', GradientType=0);
    position: relative;
}

.slider-captions {
    position: absolute;
    bottom: 200px;
}

.slider-title {
    color: #fff;
    font-size: 52px;
    font-weight: 700;
    line-height: 62px;
}

.slider-text {
    color: #fff;
}

.slider .owl-prev {
    position: absolute;
    left: 0px;
    top: 40%;
    left: 20px;
}

.slider .owl-next {
    position: absolute;
    right: 0px;
    top: 40%;
    right: 20px;
}

#slider .owl-next, #slider .owl-prev {
    background-color: transparent !important;
    border: 2px solid #fff;
    color: #fff;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

#slider .owl-next:hover, #slider .owl-prev:hover {
    background-color: #fff !important;
    border: 2px solid #fff;
    color: #15549a;
}

#slider .owl-pagination {
    margin: 0px;
    bottom: 40px;
    position: relative;
}

#slider .owl-page span {
    border-radius: 2px;
    width: 98%;
    height: 1px;
    background-color: #fff;
    margin: 0px;
}

#slider .owl-page {
    width: 5%
}

/*-----------------------------------------
index-1
------------------------------------------*/
.mail-text {
    border-right: 2px solid #1b5ca5;
}

.rate-table {
    background-color: #fff;
    border-top: 1px solid #dee7f2;
    border-bottom: 1px solid #dee7f2;
    margin-top: -21px;
}

.loan-rate {
    font-size: 32px;
    color: #414855;
    margin-bottom: 0px;
    font-weight: 600;
    line-height: 1;
}

.rate-counter-block {
    border-right: 1px solid #dee7f2;
    padding-top: 20px;
    padding-bottom: 20px;
}

.rate-counter-block:first-child {
}

.rate-icon {
    float: left;
    padding-top: 5px;
    padding-right: 20px;
    padding-left: 20px;
}

.rate-box {
}

.rate-title {
    text-transform: uppercase;
    font-size: 14px;
    color: #778191;
}

.cta {
    background-size: cover;
    margin: 0;
    min-height: 410px;
    border: none;
    padding: 100px 0px;
    background: linear-gradient(rgba(16, 75, 149, 0.8), rgba(16, 75, 149, 0.8)), rgba(16, 75, 149, 0.8) url(/front/images/cta-img.jpg) no-repeat center;
}

.testimonial-img-1 {
}

.testimonial-name-1 {
    color: #15549a;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 0px;
}

/*------------------------------------
 index-3 (updated: 16 june 2017)
-------------------------------------*/
.header-transparent {
    background-color: rgba(255, 255, 255, .1);
    padding-top: 19px;
    padding-bottom: 19px;
    border-bottom: 1px solid rgba(255, 255, 255, .08);
}

.header-transparent #navigation > ul > li.has-sub > a {
    padding-right: 30px;
}

.header-transparent #navigation > ul > li > a {
    padding: 16px 10px;
    font-size: 13px;
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
}

.header-transparent #navigation {
    float: right;
    margin-top: 0px;
    position: relative;
    z-index: 99;
}

.top-nav .langs .top-text {
    padding-left: 8px !important;
}

.top-nav .langs {
    border-right: 1px solid #fff;
    padding-right: 20px;
}

.top-nav-collapse {
    background-color: #15549a;
}

.lender-logo-section {
    margin-top: -20px;
}

.lender-box {
    margin-bottom: 8px;
    margin-top: 8px;
}

.slider-gradient-img img {
    position: relative;
    z-index: -1;
    width: 100%;
}

.slider-gradient-img {
    position: relative;
    background: -moz-linear-gradient(-45deg, rgba(31, 43, 112, 0.96) 0%, rgba(31, 43, 112, 0.66) 47%, rgba(148, 58, 158, 0.29) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(31, 43, 112, 0.96) 0%, rgba(31, 43, 112, 0.66) 47%, rgba(148, 58, 158, 0.29) 100%);
    background: linear-gradient(135deg, rgba(25, 46, 167, 0.96) 0%, rgba(24, 40, 134, 0.66) 47%, rgba(148, 58, 158, 0.29) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e61f2b70', endColorstr='#e6943a9e', GradientType=1);
}

.service-block-v3 {
    background-color: #fff;
    padding: 0;
    text-align: center;
    margin-bottom: 30px;
}

.service-block-v3 .service-content {
    padding: 5px 20px;
    height: 80px;
}

.service-block-v3 .service-title {
    margin-bottom: 20px;
    line-height: 24px;
    text-align: center;
    vertical-align: middle;
    font-size: 18px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-60%);
}

.service-rate-block {
    margin-bottom: 30px;
    padding: 0 20px;
    height: 65px;
}

.service-rate-block .product-rate {
    color: #f51f8a;
    margin-bottom: 6px;
    font-size: 18px;
}

.rate-text {
    font-size: 13px;
}

.customer-block {
    background-color: #fff;
    padding: 30px;
}

.customer-img {
    position: relative;
    margin-bottom: 30px;
}

.customer-content {
}

.customer-text {
    color: #3c4d6b;
    font-size: 21px;
    margin-bottom: 30px;
    font-family: 'Merriweather', serif;
    letter-spacing: -1px;
    font-style: italic;
    line-height: 36px;
    font-weight: normal;
}

.customer-name {
    font-size: 14px;
    font-weight: 600;
    color: #f51f8a;
}

.customer-meta {
}

.customer-quote-circle {
    background-color: #f51f8a;
    border-radius: 100%;
    padding: 10px;
    position: absolute;
    width: 40px;
    height: 40px;
    top: 20px;
    left: 30px;
    color: #fff;
    text-align: center;
    z-index: 1;
}

.how-it-block {
    background-color: #fff;
    padding: 30px;
    min-height: 320px;
}

.how-it-no {
    font-size: 76px;
    color: #15549a;
}

.how-it-content {
}

/*------------------------------------
 index-4 Students Home page
-------------------------------------*/
.students-slider-img {
}

.students-slider-img img {
    width: 100%;
}

.student-slider-img .slider-captions {
}

.fee-charges-table {
}

.fee-charges-table .list-group-item.active {
    z-index: 2;
    color: #414855;
    background-color: #ccd8e5;
    border-color: #ccd8e5;
}

.fee-charges-table .list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #eff4f9;
    border: 1px solid #d1deeb;
}

.education-rate-box {
    background-color: #eff4f9;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 5px;
}

.education-rate-number {
    font-size: 32px;
    margin-bottom: 0px;
}

/*------------------------------------
 index-5 Business Loan Home Page
-------------------------------------*/
.business-loan-products {
    background-color: #fff;
    padding: 40px;
    border: 1px solid #dee4ee;
    text-align: center;
    margin-bottom: 30px;
}

.loan-products-icon {
    margin-bottom: 20px;
}

.loan-products-content {
}

.video-play {
    position: absolute;
    top: 35%;
    left: -4%;
}

.section-about-video {
}

.about-img {
    position: relative;
}

.business-request-form {
    margin-bottom: -180px;
}

.business-request-form h3 {
    margin-bottom: 30px;
}

.business-request-form .form-group {
    margin-bottom: 0px;
}

.lender-block {
    padding: 80px 40px;
    background-color: #fff;
    border: 1px solid #dee4ee;
    border-radius: 4px;
    text-align: center;
}

.rate-badge {
    background: #104b8c;
    display: inline-block;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
}

/*-----------------------------------------------------------------------
Service Page & Block Css
----------------------------------------------------------------------*/
#service {
}

#service .owl-next, #service .owl-prev {
    background-color: transparent !important;
    border: 2px solid #a3b1c1;
    color: #a3b1c1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

#service .owl-next:hover, #service .owl-prev:hover {
    background-color: #f51f8a !important;
    border: 2px solid #f51f8a;
    color: #fff;
}

.service-block {
    transition: box-shadow .5s;
}

.service-block:hover {
    -webkit-box-shadow: 0px 4px 26px 3px rgba(213, 221, 234, 1);
    -moz-box-shadow: 0px 4px 26px 3px rgba(213, 221, 234, 1);
    box-shadow: 0px 4px 26px 3px rgba(213, 221, 234, 1);
}

.service-block:hover i {
    color: #f51f8a;
}

#service .owl-controls {
    text-align: center;
    margin-top: 10px;
}

.icon-svg-2x {
    width: 80px;
}

.icon-svg-1x {
    width: 60px;
}

/*--------------------------------
Service-img
---------------------------------*/
.service-img-box {
    transition: box-shadow .5s;
}

.service-img-box:hover {
    -webkit-box-shadow: 0px 4px 26px 3px rgba(213, 221, 234, 1);
    -moz-box-shadow: 0px 4px 26px 3px rgba(213, 221, 234, 1);
    box-shadow: 0px 4px 26px 3px rgba(213, 221, 234, 1);
}

.service-img {
    position: relative;
}

.service-content > h2 {
    margin-bottom: 0px;
}

.circle {
    background-color: #e3f0fe;
    width: 60px;
    height: 60px;
    border: 1px solid #15549a;
    border-radius: 100px;
    color: #15549a;
    margin: -70px auto 40px auto;
    padding: 10px;
    text-align: center;
    line-height: 1.7;
}

.number {
    font-size: 24px;
    font-weight: 500;
}

.number-block {
    text-align: center;
}

.number-title {
    margin-bottom: 20px;
}

.circle-large {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    line-height: 2;
    padding: 10px;
    text-align: center;
    font-size: 42px;
}

.circle-default {
    background-color: #f51f8a;
    color: #fff;
}

/*--------------------------------
testimonial
---------------------------------*/
.testimonial-block {
    position: relative;
}

.testimonial-text {
    font-family: 'Merriweather', serif;
    color: #414855;
    font-style: italic;
    line-height: 32px;
}

.testimonial-autor {
    padding-top: 15px;
    padding-bottom: 12px;
}

.testimonial-name {
    color: #fff;
    line-height: 1;
    margin-bottom: 5px;
}

.testimonial-autor-box {
}

.testimonial-title {
    color: #f51f8a;
    margin-bottom: 0px;
}

.testimonial-img {
    float: left;
    padding-right: 29px;
}

.testimonial-meta {
    font-size: 12px;
}

.testimonial-simple {
}

.testimonial-name-inverse {
    color: #15549a;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
}

.client-rating {
    margin-bottom: 10px;
}

.rate-done {
    color: #f9b218;
}

.rate-remain {
    color: #c7ced5;
}

/*--------------------------------
footer
---------------------------------*/
.newsletter-form {
    margin-bottom: 30px;
}

.newsletter-title {
    color: #778191;
    margin-top: 10px;
}

.footer {
    background-color: #1e2127;
}

.footer-logo {
    margin-bottom: 30px;
}

.widget-footer {
}

.widget-footer ul {
}

.widget-footer ul li {
    line-height: 1.6;
    margin-bottom: 8px;
}

.widget-footer ul li a {
    color: #778191;
}

.widget-footer ul li a:hover {
    color: #f51f8a;
}

.tiny-footer {
    background-color: #16191e;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.widget-footer {
    position: relative;
    padding-left: 20px;
    line-height: 32px;
}

.widget-footer ul li:before {
    font-family: 'FontAwesome';
    display: block;
    position: absolute;
    left: 0;
    font-size: 15px;
    color: #778191;
    content: "\f105";
}

.widget-social {
}

.widget-social ul {
}

.widget-social ul li {
    line-height: 1.6;
    margin-bottom: 8px;
}

.widget-social ul li a {
    color: #778191;
    text-transform: uppercase;
    font-weight: 500;
}

.widget-social ul li a i {
    width: 30px;
}

.widget-social ul li a:hover {
    color: #f51f8a;
    text-decoration: underline;
}

.address-text {
}

.call-text {
}

.call-text span {
    padding-right: 10px;
    vertical-align: -webkit-baseline-middle;
}

.footer a {
    color: #ffffff;
}

/*--------------------------------
Page header
---------------------------------*/
.page-header {
    margin: 0;
    padding: 175px 0px 80px;
    min-height: 410px;
    border: none;
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(0, 0, 0, 0.2) url(/front/images/page-header.jpg) no-repeat center;
    background-size: cover;
}

.page-title {
    margin-bottom: 0px;
    font-size: 36px;
}

.rate-block {
    text-align: right;
}

.rate-number {
    margin-bottom: 0px;
    line-height: 1;
    color: #f51f8a;
}

.btn-action {
    text-align: right;
}

.sub-nav {
    background-color: #eff3f8;
    border-top: 1px solid #dee7f2;
}

.is-sticky .sub-nav {
    position: relative;
    z-index: 9;
    top: 120px;
}

.sub-nav > .nav {
}

.sub-nav > .nav li a {
    padding: 16px;
    font-size: 14px;
    text-transform: uppercase;
    color: #7e93ae;
    font-weight: 500;
    border-right: 1px solid #dee7f2;
}

.sub-nav > .nav li a:hover {
    background-color: #fff;
    color: #f51f8a;
}

.sub-nav > .nav li a:focus {
    background-color: #fff;
    color: #f51f8a;
}

.sub-nav > .nav li.active a {
    color: red;
    background-color: #fff;
    color: #f51f8a;
}

.page-breadcrumb {
}

.page-breadcrumb .breadcrumb {
    background-color: transparent;
    padding: 0px;
    font-size: 12px;
    text-transform: uppercase;
    color: #15549a;
    font-weight: 700;
}

.page-breadcrumb .breadcrumb > li {
}

.page-breadcrumb .breadcrumb > .active {
    color: #fff;
    font-weight: 700;
}

.page-breadcrumb .breadcrumb > li a {
    color: #15549a;
    font-weight: 700;
}

.page-breadcrumb .breadcrumb > li a:hover {
    color: #f51f8a;
}

.page-breadcrumb .breadcrumb > li + li:before {
    padding: 0 5px;
    color: #15549a;
    content: "/\00a0";
}

/*--------------------------------
Blog
---------------------------------*/
.post-holder {
    border-bottom: 1px solid #e8eaec;
    margin-bottom: 29px;
}

.post-header {
    border-bottom: 1px solid #dee4ee;
    margin-bottom: 20px;
    padding-bottom: 19px;
}

.post-block {
}

.post-img {
}

.meta {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    color: #a4a7ac;
    margin-bottom: 14px;
    margin-top: 14px;
}

.meta span {
    margin-right: 0px;
}

.meta span:after {
    margin-left: 15px;
    margin-right: 15px;
    color: #b0b0b0;
    content: "|";
}

.meta span:last-child:after {
    margin-left: 0px;
    margin-right: 0px;
    content: "";
}

/*----- Related Post-----*/
.post-related {
    border-bottom: 1px solid #e8eaec;
}

.post-related-title {
}

.post-related-content {
}

.related-title {
    font-size: 18px;
    color: #4b5565;
}

.related-img {
}

/*----- prev-next-----*/
.post-navigation {
    border-bottom: 1px solid #e8eaec;
    margin-bottom: 29px;
    padding-bottom: 30px;
}

.nav-links {
}

.nav-previous {
}

.prev-link, .next-link {
    color: #778191;
    margin-bottom: 10px;
    display: block;
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
}

.nav-next {
}

.next-link {
}

.prev-link {
}

.prev-link:hover, .next-link:hover {
    color: #f51f8a
}

.meta-date {
    color: #f51f8a;
    font-weight: 600;
}

.meta-author {
}

.meta-comments {
}

#post-gallery {
}

.quote-block {
}

.meta-link {
    color: #a4a7ac;
}

/*--------------------------------
Blog-sidebar
---------------------------------*/
.widget {
    padding: 30px;
    margin-bottom: 30px;
}

.widget-title {
    border-bottom: 1px solid #e8eaec;
    margin-bottom: 19px;
    padding-bottom: 20px;
}

.widget-search {
}

.widget-category {
}

.widget-category li a, .widget-archives li a {
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
    line-height: 14px;
    color: #797b80;
    font-size: 14px;
}

.widget-category li a:hover, .widget-archives li a:hover {
    color: #f51f8a;
}

.widget-tags {
}

.widget-tags a {
    text-transform: uppercase;
    border: 2px solid #c3c7d1;
    padding: 9px 16px;
    display: inline-block;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    color: #9a9fab;
    border-radius: 4px;
    letter-spacing: 1px;
}

.widget-tags a:hover {
    background-color: #f51f8a;
    color: #fff;
    border-color: #f51f8a;
}

/*----- post-author-----*/
.post-author {
    border-bottom: 1px solid #e8eaec;
    margin-bottom: 29px;
    padding-bottom: 30px;
}

.author-img {
}

.author-bio {
}

.author-header {
}

.author-header small {
    text-transform: uppercase;
    color: #de4526;
    font-weight: 700;
    margin-left: 15px;
}

.author-content {
}

.author-social {
}

.author-social a {
    color: #707070;
    display: inline-block;
    text-align: center;
    font-size: 13px;
    padding: 8px 12px;
}

.author-social a:hover {
    background-color: #de4526;
    color: #fff;
}

/*----- comments-----*/
.comments-area {
    border-bottom: 1px solid #e8eaec;
    padding-bottom: 40px;
}

.comments-title {
    margin-bottom: 40px;
}

.comment-list {
}

.comment {
}

.comment-body {
    margin-bottom: 60px;
}

.comment-author {
}

.comment-author img {
    width: 120px;
    float: left;
}

.comment-info {
    padding-left: 160px;
}

.comment-header {
}

.user-title {
    margin-bottom: 0px;
}

.comment-meta {
    margin-bottom: 20px;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
}

.comment-meta-date {
}

.comment-content {
}

.reply {
}

.reply-title {
    margin-bottom: 40px;
}

.childern {
    padding-left: 140px;
}

.leave-comments {
    background-color: #f3f5f5;
}

.widget-share {
    background-color: #fff;
    padding: 14px;
    position: absolute;
    top: 0px;
    right: -69px;
}

.btn-share {
    padding: 12px 16px;
    display: inline-block;
    line-height: 1;
    margin-bottom: 2px;
    font-size: 14px;
    width: 40px;
}

.btn-facebook {
    background-color: #3b5998;
    color: #fff;
}

.btn-facebook:hover {
    background-color: #3b5998;
    color: #fff;
}

.btn-twitter {
    background-color: #1da1f2;
    color: #fff;
}

.btn-twitter:hover {
    background-color: #1da1f2;
    color: #fff;
}

.btn-google {
    background-color: #dc483a;
    color: #fff;
}

.btn-google:hover {
    background-color: #dc483a;
    color: #fff;
}

.btn-linkedin {
    background-color: #88b5;
    color: #fff;
}

.btn-linkedin:hover {
    background-color: #88b5;
    color: #fff;
}

/*--------------------------------
Blog-single
---------------------------------*/
.name {
    font-size: 14px;
    text-transform: uppercase;
    color: #f51f8a;
    font-weight: 600;
    padding-left: 30px;
}

/*--------------------------------
Pagination
---------------------------------*/
.st-pagination {
}

.st-pagination .pagination > li > a, .pagination > li > span {
    position: relative;
    float: left;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #9a9fab;
    text-decoration: none;
    font-size: 12px;
    border: 2px solid #c3c7d1;
    font-weight: 600;
    border-radius: 4px;
    text-transform: uppercase;
    margin-right: 3px;
    margin-bottom: 5px;
    background-color: #fff;
    padding: 8px 13px;
}

.st-pagination .pagination > li > a:focus, .st-pagination .pagination > li > a:hover, .st-pagination .pagination > li > span:focus, .st-pagination .pagination > li > span:hover {
    z-index: 2;
    color: #fff;
    background-color: #f51f8a;
    border-color: #f51f8a;
    border-radius: 4px;
}

.st-pagination .pagination > li:first-child > a, .st-pagination .pagination > li:first-child > span {
    margin-left: 0;
    border-radius: 4px;
}

.st-pagination .pagination > li:last-child > a, .st-pagination .pagination > li:last-child > span {
    border-radius: 4px;
}

.st-pagination .pagination > .active > a, .st-pagination .pagination > .active > a:focus, .st-pagination .pagination > .active > a:hover, .st-pagination .pagination > .active > span, .st-pagination .pagination > .active > span:focus, .st-pagination .pagination > .active > span:hover {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #f51f8a;
    border-color: #f51f8a;
    border-radius: 4px;
}

.st-accordion {
}

.st-accordion .sign {
    color: #3c4d6b;
    padding: 0 2px;
    position: absolute;
    left: 0px;
    top: 5px;
    margin-left: -20px;
    font-size: 14px;
}

.st-accordion .accordion .panel-heading.active .sign {
    color: #95cd40;
}

.st-accordion .panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    color: inherit;
    position: relative;
    color: #3c4d6b;
}

.sign {
    color: #084988;
    padding: 0 2px;
    position: absolute;
    right: -10px;
    top: 6px;
}

.st-accordion .panel-heading.active .sign {
    color: #f51f8a;
}

.st-accordion .panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 0px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.st-accordion .panel-default {
    border-color: transparent;
    border-bottom: 1px solid #e0e7ea;
}

.st-accordion .panel-group .panel {
    margin-bottom: 10px;
    border-radius: 0px;
    -webkit-box-shadow: 0px 0px 0px 0px rgba(234, 234, 234, 1);
    -moz-box-shadow: 0px 0px 0px 0px rgba(234, 234, 234, 1);
    box-shadow: 0px 0px 0px 0px rgba(234, 234, 234, 1);
}

.st-accordion .panel-default > .panel-heading {
    color: #323734;
    background-color: #fff;
    border-bottom: 1px solid transparent;
    padding: 10px 0px 10px 30px;
}

.st-accordion .panel-group .panel-heading + .panel-collapse > .list-group, .st-accordion .panel-group .panel-heading + .panel-collapse > .panel-body {
}

.st-accordion .panel-body {
    padding: 30px;
}

.st-accordion .panel-group .panel-heading + .panel-collapse > .list-group, .panel-group .panel-heading + .panel-collapse > .panel-body {
    border-top: 1px solid transparent;
}

/*--------------------------------
Extra Plugin CSS - Back to top
---------------------------------*/
.cd-top {
    z-index: 9999;
    display: inline-block;
    height: 40px;
    width: 40px;
    position: fixed;
    bottom: 40px;
    right: 10px;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    visibility: hidden;
    border-radius: 5px;
    background: rgba(245, 31, 138, 100) url(/front/images/cd-top-arrow.svg) no-repeat center 50%;
    opacity: 0;
    -webkit-transition: opacity .3s 0s, visibility 0s .3s;
    -moz-transition: opacity .3s 0s, visibility 0s .3s;
    transition: opacity .3s 0s, visibility 0s .3s;
}

.cd-top.cd-is-visible, .cd-top.cd-fade-out, .no-touch .cd-top:hover {
    -webkit-transition: opacity .3s 0s, visibility 0s 0s;
    -moz-transition: opacity .3s 0s, visibility 0s 0s;
    transition: opacity .3s 0s, visibility 0s 0s;
}

.cd-top.cd-is-visible { /* the button becomes visible */
    visibility: visible;
    opacity: 1;
}

.cd-top.cd-fade-out { /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
    opacity: .5;
}

.no-touch .cd-top:hover {
    background-color: #cb2529;
    opacity: 1;
}

/*-------- filter gallery -------*/
.isotope-item {
    z-index: 2;
}

.isotope-hidden.isotope-item {
    pointer-events: none;
    z-index: 1;
}

.isotope, .isotope .isotope-item { /* change duration value to whatever you like */
    -webkit-transition-duration: 0.8s;
    -moz-transition-duration: 0.8s;
    transition-duration: 0.8s;
}

.isotope {
    -webkit-transition-property: height, width;
    -moz-transition-property: height, width;
    transition-property: height, width;
}

.isotope .isotope-item {
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    transition-property: transform, opacity;
}

.portfolioContainer {
}

.portfolioFilter {
    margin-bottom: 30px;
    text-align: center;
}

.portfolioFilter a {
    text-transform: uppercase;
    background-color: #eff3f8;
    padding: 9px 26px;
    display: inline-block;
    color: #7e93ae;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 5px;
    border: 1px solid #dee7f2;
}

.portfolioFilter a:hover {
    color: #fff;
    background-color: #f51f8a;
    border: 1px solid #f51f8a;
}

.portfolioFilter a.current {
    color: #fff;
    text-decoration: none;
    background-color: #f51f8a;
    border: 1px solid #f51f8a;
}

/*---------- Thumbnail hover effect -------*/

/* ============================================================
GLOBAL
============================================================ */

.effects {
}

.effects .img {
    position: relative;
}

.effects .img:nth-child(n) {
}

.effects .img:first-child {
    margin-bottom: 20px;
}

.effects .img:last-child {
    margin-right: 0;
}

.effects .img img {
    display: block;
    margin: 0;
    padding: 0;
    max-width: 100%;
    height: auto;
}

.overlay {
    display: block;
    position: absolute;
    z-index: 20;
    background: rgba(246, 96, 171, 0.8);
    overflow: hidden;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

a.close-overlay {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    width: 45px;
    height: 45px;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    line-height: 45px;
    text-align: center;
    background-color: #de4526;
    cursor: pointer;
}

a.close-overlay.hidden {
    display: none;
}

a.expand {
    display: block;
    position: absolute;
    z-index: 100;
    width: 60px;
    height: 60px;
    border: solid 5px #fff;
    text-align: center;
    color: #fff;
    line-height: 50px;
    font-weight: 700;
    font-size: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px;
}

/* ============================================================
EFFECT 6 - ICON BOUNCE IN
============================================================ */
#effect-6 .overlay {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
}

#effect-6 .overlay a.expand {
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
    opacity: 0;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

#effect-6 .img.hover .overlay {
    opacity: 1;
}

#effect-6 .img.hover .overlay a.expand {
    top: 50%;
    margin-top: -30px;
    opacity: 1;
}

/*----------------- Gallery Wide ---------------*/
.gallery-thumbnail {
}

.gallery-thumbnail img {
    width: 100%;
}

.gallery-img img {
    width: 100%;
}

.gallery-block {
}

/*----------------- Image Hover Effect ---------------*/
img {
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    line-height: normal;
    vertical-align: middle;
}

.imghover {
    display: block;
    position: relative;
    max-width: 100%;
}

.imghover::before, .imghover::after {
    display: block;
    position: absolute;
    content: "";
    text-align: center;
    opacity: 0;
}

.imghover::before {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.imghover::after {
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    line-height: 50px;
    margin: -25px 0 0 -25px;
    content: "\f06e"; /* fa-eye */
    font-family: "FontAwesome";
    font-size: 36px;
}

.imghover:hover::before, .imghover:hover::after {
    opacity: 1;
}

.imghover:hover::before {
    background: rgba(34, 62, 92, .55);
}

.imghover:hover::after {
    color: #FFFFFF;
}

/*--------------------------------
Team
---------------------------------*/
.team-block {
}

.team-img {
}

.team-content {
}

.designation {
    font-size: 12px;
    color: #f51f8a;
    text-transform: uppercase;
    font-weight: 600;
}

/*--------------------------------
About us
---------------------------------*/
.about-section {
}

.counter-section {
}

.why-choose-section {
}

.testimonial-section {
}

.cta-section {
}

.text-big {
    font-size: 20px;
    color: #f51f8a;
    font-weight: 600;
    margin-bottom: 0px;
}

/*--------------------------------
Contact us
---------------------------------*/
.map {
    height: 400px;
}

.widget-contact {
    margin-top: 30px;
    margin-bottom: 30px;
}

.widget-contact li {
    margin-bottom: 10px;
    border-bottom: 1px solid #eff3f8;
    padding-bottom: 10px;
}

.widget-contact li span {
    width: 120px;
    display: inline-block;
}

/*----------------- Gallery Wide ---------------*/
.gallery-thumbnail img {
    width: 100%;
}

.gallery-img img {
    width: 100%;
}

.gallery-block {
}

.slide-ranger .ui-widget-header {
    background-color: #f51f8a;
}

.slide-ranger .ui-state-default, .ui-widget-content .ui-state-default {
    background-color: #fff !important;
}

.slide-ranger .ui-widget.ui-widget-content {
    border: 1px solid #f51f8a;
}

.slide-ranger .ui-slider .ui-slider-handle {
    border-radius: 100%;
    border: 3px solid #f51f8a;
}

/*--------------------------------
Product Single Sidebar
---------------------------------*/
.product-tabs {
}

.product-tabs ul {
    position: relative;
    z-index: 9;
}

.product-tabs .nav > li {
    margin-top: -1px;
}

.product-tabs .nav > li.active a {
    border-right: 1px solid #f4f6f8;
    background: #eff3f8;
    color: #f51f8a;
}

.product-tabs .nav > li > a {
    background: #fff;
    border-bottom: 1px solid #dee7f2;
    border-right: 1px solid #dee7f2;
    border-left: 1px solid #dee7f2;
    border-top: 1px solid #dee7f2;
    color: #7e93ae;
}

.product-tabs .nav > li > a:hover {
    background: #eff3f8;
    border-bottom: 1px solid #dee7f2;
    border-right: 1px solid transparent;
    border-left: 1px solid #dee7f2;
    border-top: 1px solid #dee7f2;
    color: #f51f8a;
}

.product-tabs .nav > li > a i {
    padding-right: 10px;
}

.tab-content {
    background: #eff3f8;
    padding: 40px;
    border: 1px solid #dee7f2;
    position: relative;
    z-index: 8;
    margin-left: -1px;
    margin-top: -1px;
}

.fee-table {
}

/*--------------------------------
EMI Calculator
---------------------------------*/
.loan-table {
    margin-top: 40px;
}

.table-head {
    background: #eff3f8;
}

.loan-title {
    margin-bottom: 30px;
}

.table-bordered {
    border: 1px solid #dee4ee;
    color: #333;
}

.table-bordered > tbody > tr > td, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > td, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > thead > tr > th {
    border: 1px solid #dee4ee;
    color: #3c4d6b;
}

.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #fafcfd;
}

/*--------------------------------
Card Listing Page
---------------------------------*/
.card-listing {
    padding: 26px;
    border: 1px solid #dee7f2;
    margin-bottom: 30px;
    background: #eff3f8;
}

.card-img {
}

.card-img img {
}

.card-content {
    margin-top: 20px;
}

.card-content .btn {
    margin-right: 30px;
}

.card-name {
}

.card-features {
    margin-top: 20px;
}

.card-features ul li {
    border-bottom: 1px solid #eff3f8;
    padding-bottom: 10px;
    margin-bottom: 8px;
    line-height: 1.5;
}

.card-details {
}

.card-head-sections {
    margin-bottom: 40px;
}

/*--------------------------------
Lender Listing Page
---------------------------------*/
.lender-listing {
    border: 1px solid #dee7f2;
    margin-bottom: 30px;
}

.lender-head {
    padding: 15px;
    background: #eff3f8;
    border-bottom: 1px solid #dee7f2;
}

.lender-logo {
    display: inline-block;
    width: 170px;
}

.lender-reviews {
    color: #ffb607;
    display: inline-block;
    float: right;
    padding-top: 15px;
}

.lender-rate-box {
    padding: 15px;
    border-bottom: 1px solid #dee7f2;
}

.lender-ads-rate {
    border-right: 1px solid #dee7f2;
}

.lender-ads-rate, .lender-compare-rate {
    display: inline-block;
    text-align: center;
    width: 49%;
}

.lender-rate-value {
    font-size: 20px;
    font-weight: 600;
    margin-top: 6px;
}

.compare-rate {
}

.lender-feature-list {
    padding: 20px;
    border-bottom: 1px solid #dee7f2;
}

.lender-feature-list ul {
    margin-bottom: 0px;
}

.lender-feature-list ul li {
    font-size: 14px;
    margin-bottom: 8px;
}

.lender-actions {
    text-align: center;
    padding: 20px;
}

.lender-actions .btn-link {
    margin-top: 30px;
    font-size: 14px;
}

.lender-details {
}

.lender-head-sections {
    margin-bottom: 60px;
}

.lender-img {
}

.lender-name {
}

.lender-rates-list {
    background-color: #eff4f9;
    padding: 20px;
    border-radius: 4px;
}

.lender-loan-sections {
}

.lender-apply {
}

.lender-features {
}

.lender-loan-sections, .lender-apply, .lender-features, .lender-rates-list {
    margin-bottom: 60px;
}

/*--------------------------------
Loan Eligibility Page
---------------------------------*/
.loan-eligibility-block {
    padding: 30px;
    background: #eff3f8;
}

.loan-eligibility-form .input-group-addon {
    border-left: 2px solid #e6ecef;
    border-top: 2px solid #e6ecef;
    border-bottom: 2px solid #e6ecef;
    background: #fff;
}

.loan-eligibility-form .control-label {
    margin-bottom: 10px;
}

.loan-eligibility-info {
    font-size: 70px;
    line-height: 70px;
    font-weight: 600;
    color: #15549a;
}

.loan-eligibility-form .form-group {
    display: block;
}

.eligibility-amount {
    font-size: 52px;
    font-weight: 600;
    color: #15549a;
}

.eligibility-text {
    font-size: 21px;
}

/*-----------------------------------------
Shortcodes
------------------------------------------*/
.st-tabs { /*border: 1px solid #dee7f2; */
}

.st-tabs .nav-tabs {
    border-bottom: 1px solid transparent;
}

.st-tabs .nav-tabs > li > a {
    margin-right: 0px;
    line-height: 1.42857143;
    border: 1px solid #dee7f2;
    border-radius: 2px 2px 0 0;
    background-color: #eff3f8;
    font-size: 14px;
    text-transform: uppercase;
    color: #7e93ae;
    font-weight: 500;
    padding: 20px 8px;
}

.st-tabs .nav-tabs > li > a:hover {
    background-color: #fff;
    border-bottom: 1px solid #dee7f2;
    color: #f51f8a;
    border-color: transparent;
}

.st-tabs .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
    color: #8f979e;
    cursor: default;
    background-color: #fff;
    border: 1px solid #dee7f2;
    border-bottom-color: transparent;
}

.st-tabs .tab-content {
    padding: 30px;
    background-color: #fff;
    border-left: 1px solid #dee7f2;
    border-right: 1px solid #dee7f2;
    border-bottom: 1px solid #dee7f2;
    margin-top: -2px;
}

.st-tabs .nav-tabs.nav-justified > .active > a, .st-tabs .nav-tabs.nav-justified > .active > a:focus, .st-tabs .nav-tabs.nav-justified > .active > a:hover {
    border: 1px solid #dee7f2;
    border-bottom: transparent;
    color: #f51f8a;
}

.column-block {
    margin-bottom: 30px;
}

/*-----------------------------------------
Compare-Loan
------------------------------------------*/
.compare-title {
}

.compare-block {
}

.compare-row {
}

.rate {
}

.rate, .fees, .compare-rate, .repayment {
    font-size: 18px;
    color: #4b5565;
    margin-bottom: 0px;
    margin-top: 15px;
    font-weight: 600;
}

.fees {
}

.compare-rate {
}

.repayment {
}

.comapre-action {
    padding-top: 15px;
}

/*-----------------------------------------
Error-page
------------------------------------------*/
.error-img {
    text-align: center;
}

.error-title {
    font-size: 36px;
    color: #192139;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.msg {
    font-size: 24px;
    color: #f51f8a;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
}

/*-----------------------------------------
Header - 2
------------------------------------------*/
.header-2 {
    background-color: #fff;
    padding-top: 10px;
    position: relative;
    z-index: 9
}

.header-action {
    margin-top: 10px;
}

.header-2 .navigation-2 {
    background-color: #fff;
    border-top: 1px solid #dee7f2;
    margin-top: 20px;
    border-bottom: 1px solid #dee7f2;
}

.header-2 #navigation {
    float: left;
    margin-top: 0px;
    background-color: #fff;
}

.header-2 #navigation > ul > li > a {
    padding: 16px 30px;
    font-size: 13px;
    text-decoration: none;
    color: #66707f;
    font-weight: 600;
    text-transform: uppercase;
    border-left: 1px solid #dee7f2;
}

.header-2 #navigation > ul > li.has-sub > a {
    padding-right: 50px;
}

.header-2 #navigation > ul > li.has-sub > a:before {
    position: absolute;
    right: 28px;
    font-family: FontAwesome;
    content: "\f107";
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}

/*-----------------------------------------
Landing Page Template
------------------------------------------*/
.hero {
    position: relative;
    padding-top: 60px;
    padding-bottom: 100px;
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(0, 0, 0, 0.2) url(/front/images/lp-car-image.jpg) no-repeat center;
    background-size: cover;
}

.hero-home-loan {
    position: relative;
    padding-top: 60px;
    padding-bottom: 100px;
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(0, 0, 0, 0.2) url(/front/images/home-loan-lp.jpg) no-repeat center;
    background-size: cover;
}

.hero-title {
    font-size: 48px;
    line-height: 52px;
    color: #fff;
    font-weight: 800;
    letter-spacing: -1px;
    margin-bottom: 30px;
}

.hero-captions {
    padding-top: 80px;
}

.hero-text {
    color: #fff;
}

.request-form {
    background-color: #fff;
    padding: 30px;
    border-radius: 4px;
}

.form-title {
    color: #104b8c;
    font-size: 30px;
}

.price-rate {
    margin-top: 30px;
}

.price-big {
    font-size: 40px;
    color: #fff;
    font-weight: 800;
}

.price-rate small {
    color: #fff;
    text-transform: uppercase;
}

.new-price, .old-price {
    margin-bottom: 30px;
}

/*--------------- Features block class --------------*/
.feature {
}

.feature-icon {
}

.feature .feature-icon {
    margin-bottom: 20px;
}

/*-------- feature icon left --------*/
.feature-left {
}

.feature-left .feature-icon {
    float: left;
    color: #15549a;
    font-size: 28px;
    line-height: 1;
}

.feature-left .feature-content {
    padding-left: 60px;
}

.feature-content {
}

/*--------- feature center --------*/
.feature-center {
    text-align: center;
}

.feature-center .feature-icon {
    margin-bottom: 20px;
}

.feature-icon-style {
    text-align: center;
}

.feature-icon-style .feature-icon {
    margin: 0 auto 40px auto;
    background-color: #f4f6f8;
    width: 80px;
    height: 80px;
    border-radius: 100px;
    padding: 24px;
    text-align: center;
    color: #15549a;
}

.feature-icon-style .feature-title {
    margin-bottom: 16px;
}

.call-to-action {
    color: #fff;
}

.feature-circle {
    background-color: #f4f6f8;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    padding: 14px;
    color: #15549a;
    font-size: 26px;
    text-align: center;
}

/*----- updated in v 1.0.2 ----*/
.feature-bg {
    background-color: #e3edf9;
    border-radius: 4px;
    padding: 60px;
    text-align: center;
}

.feature-bg .feature-icon {
    background-color: #bacadd;
    color: #3c4d6b;
    width: 120px;
    height: 120px;
    border-radius: 120px;
    font-size: 40px;
    text-align: center;
    line-height: 120px;
    margin: 0 auto 40px auto;
}

.feature-bg .feature-content {
}

.feature-list-bar {
    background: #e3edf9;
    padding-top: 16px;
    padding-bottom: 16px;
    color: #3c4d6b;
}

@media screen and (max-width: 1366px) {
    .slider-captions {
        position: absolute;
        bottom: 70px;
        width: 100%;
    }

    .slider-title {
        color: #fff;
        font-size: 32px;
        font-weight: 700;
        line-height: 42px;
    }

    .slider .owl-prev {
        position: absolute;
        left: 0px;
        top: 40%;
        left: 0px;
    }

    .slider .owl-next {
        position: absolute;
        right: 0px;
        top: 40%;
        right: 0px;
    }
}

@media screen and (min-width: 1300px) and (max-width: 1440px) {
    .slider-captions {
        position: absolute;
        bottom: 100px;
        width: 100%;
    }
}

/*============= media query max-width: 768px; =============*/
@media only screen and (max-width: 768px) {

    /* start query */
    .header {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .logo {
    }

    /*--------------- slider for mobile view ( for mobile view  )---------- */
    .slider-captions {
        position: absolute;
        bottom: 28px;
        text-align: center;
    }

    .slider-title {
        color: #fff;
        font-size: 22px;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 26px;
    }

    .slider .owl-prev {
        position: absolute;
        left: 0px;
        top: 30%;
        left: 0px;
    }

    .slider .owl-next {
        position: absolute;
        right: 0px;
        top: 30%;
        right: 0px;
    }

    /*--------------- Page Header ( for mobile view  )---------- */
    .page-header {
        padding: 40px 0px 80px;
    }

    .page-title {
        font-size: 28px;
        margin-bottom: 14px;
    }

    .btn-action {
        text-align: center;
    }

    .btn-action .btn {
        padding: 10px;
        float: left;
        margin-left: 5px;
    }

    .sub-nav > .nav li a {
        border-bottom: 1px solid #dee7f2;
        margin-bottom: 0px;
    }

    .childern {
        padding-left: 0px;
    }

    .widget-share {
        background-color: #fff;
        padding: 14px;
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .search-nav {
        display: none;
    }

    .rate-counter-block {
        text-align: center;
    }

    .rate-icon {
        float: none;
        margin-bottom: 10px;
    }

    .loan-rate {
        font-size: 24px;
    }

    /*--------------- index - 4 ()-------------*/
    .header-transparent {
        position: relative;
        background-color: #15549a;
    }

    .header-transparent #navigation.small-screen #menu-button {
        display: block;
        padding: 16px 0px;
        color: #f51f8a;
        cursor: pointer;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
        border-top: 1px solid #0a4587;
        margin-top: 20px;
    }

    .header-transparent #navigation.small-screen ul li {
        width: 100%;
        border-top: 1px solid #0a4587;
    }

    .header-transparent #navigation.small-screen .submenu-button {
        position: absolute;
        z-index: 99;
        right: 0;
        top: 0;
        display: block;
        border-left: 1px solid #0a4587;
        height: 46px;
        width: 46px;
        cursor: pointer;
    }

    .header-transparent #navigation.small-screen ul ul li a {
        color: #fff;
        background: none;
    }
}

/* start query */
@media only screen and (max-width: 320px) {

    /*--------------- slider for mobile view ( for mobile view  )---------- */
    .slider-captions {
        position: absolute;
        bottom: 18px;
        text-align: center;
        width: 100%;
    }

    .slider-title {
        color: #fff;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 26px;
    }

    .slider .owl-prev {
        position: absolute;
        left: 0px;
        top: 30%;
        left: 0px;
    }

    .slider .owl-next {
        position: absolute;
        right: 0px;
        top: 30%;
        right: 0px;
    }

    .rate-icon {
        float: none;
    }

    .rate-counter-block {
        text-align: center;
    }

    .loan-rate {
        font-size: 24px;
    }

    .widget-share {
        background-color: #fff;
        padding: 14px;
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .search-nav {
        display: none;
    }
}

.user-side {
    border-left: 2px solid #fff !important;
    margin-left: 20px !important;
    padding-left: 15px !important;
}

.pink {
    color: #f5208a;
}