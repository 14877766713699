.search {
    margin-bottom: 30px;
}
.search .title {
    margin: 25px 0;
}
/* Categories */
.categories {

}
.categories ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.categories li {
    padding: 3px 10px;
}
.categories li + li {
    border-top: 1px solid #eee;
}
.categories li a {
    display: block;
    position: relative;
    line-height: 43px;
    height: 43px;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #717f86;
    padding-left: 5px;
    font-size: 12px;
    -webkit-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
}

.categories ul li a img {
    width: 25px;
    height: 25px;
    margin-right: 15px;
}
.categories .all-cat {
    padding: 8px 0 8px 50px;
}
.categories .all-cat a {
    font-size: 13px;
}
.categories ul li.active a, .categories ul li a:hover {
    color: #f5208a;
}


/* Listing */
.product .lender-rate-box{
    border-top: 1px solid #dee7f2;
}
.product .lender-ads-rate .lender-rate-value {
    color: #f5208a;
}
.expired {
    margin: 20px 0;
}


/* Code Modal */
#ajaxModal .modal-header {
    border-bottom: none !important;
}
.code-content {
    text-align: center;
    padding: 0 40px;
}
.code-content .store-logo img{
    width: 30%;
}
.code-content .title {
    font-size: 24px;
    margin: 20px auto;
}
.code-content .description {
    color: #717f86 !important;
}
.code-content .store-link {
    margin: 0 auto 15px;
    width: 50%;
}
.code-content .code {
    margin-top: 40px;
}
.code-content .code p{
    text-transform: uppercase;
}
.code-content .code .code-label{
    width: 50%;
    display: inline-block;
    font-size: 21px;
    position: relative;
    margin: 0 auto 20px;
    padding: 5px 30px;
    text-align: center;
    border: 2px dashed azure;
    background-color: #d9edf7;
    color: #F50;
}

/* User */
.visa {
    width: 135px;
    margin-left: 15px;
}
.sub-page-title {
    margin-bottom: -21px;
}
.addresses .education-rate-box {
    text-align: center;
    border: 1px solid #d1deeb;
}
.btn {
    max-width: 100%;
    overflow: hidden;
}
.attention {
    background: #f5208a !important;
    color: #fff !important;
}

.my-packages .compare-title span:not(:last-child):after{
    content: "|";
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 500;
}
.my-packages .compare-title span.date{
    font-weight: bold;
}
.my-packages .compare-title span.flag img {
    width: 24px;
}
.my-packages .web_logo {
    height: 30px;
    max-width: 45%;
}
.my-packages .shipping_price {
    margin-top: 21px;
    display: inline-block;
    background: #fff;
}
.my-packages .shipping_price:before {
    content: "|";
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 500;
    color: #cccccc;
}
.my-packages .nav li a i {
    margin-right: 5px;
}
.my-packages .st-tabs .tab-content {
    padding: 30px 20px;
}
.declaration {
    padding: 0 40px;
}
.post-img img {
    margin: 0 auto;
    object-fit: cover;
    width: 100%;
    height: 200px;
}
.coupon-item .service-block-v3 .service-content {
    height: 45px;
}
.coupon-item .service-rate-block {
    height: 60px;
}
select.select2-container {
    -webkit-appearance: menulist;
}

@media only screen and (max-width: 768px) {
    .slider-title {
        font-size: 13px;
    }
    .slider-captions * {
        color: #fff;
        line-height: 1;
        font-size: 15px !important;
    }
}