@font-face {
  font-family: 'fontello';
  src: url('/front/fonts/fontello.eot?72294633');
  src: url('/front/fonts/fontello.eot?72294633#iefix') format('embedded-opentype'),
       url('/front/fonts/fontello.woff2?72294633') format('woff2'),
       url('/front/fonts/fontello.woff?72294633') format('woff'),
       url('/front/fonts/fontello.ttf?72294633') format('truetype'),
       url('/front/fonts/fontello.svg?72294633#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('/front/font/fontello.svg?72294633#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  
   
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
 
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-file:before { content: '\e800'; } /* '' */
.icon-flag:before { content: '\e801'; } /* '' */
.icon-folder:before { content: '\e802'; } /* '' */
.icon-gardening:before { content: '\e803'; } /* '' */
.icon-hourglass:before { content: '\e804'; } /* '' */
.icon-info:before { content: '\e805'; } /* '' */
.icon-letter:before { content: '\e806'; } /* '' */
.icon-light-bulb:before { content: '\e807'; } /* '' */
.icon-list:before { content: '\e808'; } /* '' */
.icon-loupe:before { content: '\e809'; } /* '' */
.icon-money:before { content: '\e80a'; } /* '' */
.icon-money-1:before { content: '\e80b'; } /* '' */
.icon-money-bag:before { content: '\e80c'; } /* '' */
.icon-office-chair:before { content: '\e80d'; } /* '' */
.icon-phone-call:before { content: '\e80e'; } /* '' */
.icon-podium:before { content: '\e80f'; } /* '' */
.icon-productivity:before { content: '\e810'; } /* '' */
.icon-puzzle:before { content: '\e811'; } /* '' */
.icon-reporting:before { content: '\e812'; } /* '' */
.icon-rocket:before { content: '\e813'; } /* '' */
.icon-shoe:before { content: '\e814'; } /* '' */
.icon-speedometer:before { content: '\e815'; } /* '' */
.icon-speedometer-1:before { content: '\e816'; } /* '' */
.icon-stemware:before { content: '\e817'; } /* '' */
.icon-tactics:before { content: '\e818'; } /* '' */
.icon-tactics-1:before { content: '\e819'; } /* '' */
.icon-tag:before { content: '\e81a'; } /* '' */
.icon-target:before { content: '\e81b'; } /* '' */
.icon-task:before { content: '\e81c'; } /* '' */
.icon-time:before { content: '\e81d'; } /* '' */
.icon-woman:before { content: '\e81e'; } /* '' */
.icon-worldwide:before { content: '\e81f'; } /* '' */
.icon-address-book:before { content: '\e820'; } /* '' */
.icon-address-book-1:before { content: '\e821'; } /* '' */
.icon-aim:before { content: '\e822'; } /* '' */
.icon-alarm-clock:before { content: '\e823'; } /* '' */
.icon-avatar:before { content: '\e824'; } /* '' */
.icon-balance:before { content: '\e825'; } /* '' */
.icon-briefcase:before { content: '\e826'; } /* '' */
.icon-briefcase-1:before { content: '\e827'; } /* '' */
.icon-calendar:before { content: '\e828'; } /* '' */
.icon-calendar-1:before { content: '\e829'; } /* '' */
.icon-charging:before { content: '\e82a'; } /* '' */
.icon-clock:before { content: '\e82b'; } /* '' */
.icon-clock-1:before { content: '\e82c'; } /* '' */
.icon-coffee-cup:before { content: '\e82d'; } /* '' */
.icon-compass:before { content: '\e82e'; } /* '' */
.icon-cup:before { content: '\e82f'; } /* '' */
.icon-distribution:before { content: '\e830'; } /* '' */
.icon-document:before { content: '\e831'; } /* '' */
.icon-coffee-cup-1:before { content: '\e832'; } /* '' */
.icon-cogwheel:before { content: '\e833'; } /* '' */
.icon-coins:before { content: '\e834'; } /* '' */
.icon-command:before { content: '\e835'; } /* '' */
.icon-compass-1:before { content: '\e836'; } /* '' */
.icon-cup-1:before { content: '\e837'; } /* '' */
.icon-diagram:before { content: '\e838'; } /* '' */
.icon-dialog:before { content: '\e839'; } /* '' */
.icon-documentation:before { content: '\e83a'; } /* '' */
.icon-dollar-bill:before { content: '\e83b'; } /* '' */
.icon-dollar-coins:before { content: '\e83c'; } /* '' */
.icon-employee:before { content: '\e83d'; } /* '' */
.icon-employees:before { content: '\e83e'; } /* '' */
.icon-flag-1:before { content: '\e83f'; } /* '' */
.icon-flask:before { content: '\e840'; } /* '' */
.icon-glasses:before { content: '\e841'; } /* '' */
.icon-horse:before { content: '\e842'; } /* '' */
.icon-laptop:before { content: '\e843'; } /* '' */
.icon-lifebuoy:before { content: '\e844'; } /* '' */
.icon-light-bulb-1:before { content: '\e845'; } /* '' */
.icon-line-graph:before { content: '\e846'; } /* '' */
.icon-loupe-1:before { content: '\e847'; } /* '' */
.icon-medal:before { content: '\e848'; } /* '' */
.icon-megaphone:before { content: '\e849'; } /* '' */
.icon-microscope:before { content: '\e84a'; } /* '' */
.icon-mortarboard:before { content: '\e84b'; } /* '' */
.icon-mountain:before { content: '\e84c'; } /* '' */
.icon-paper-plane:before { content: '\e84d'; } /* '' */
.icon-piggy-bank:before { content: '\e84e'; } /* '' */
.icon-pointer:before { content: '\e84f'; } /* '' */
.icon-rocket-1:before { content: '\e850'; } /* '' */
.icon-smartphone:before { content: '\e851'; } /* '' */
.icon-smartphone-1:before { content: '\e852'; } /* '' */
.icon-speedometer-2:before { content: '\e853'; } /* '' */
.icon-statistics:before { content: '\e854'; } /* '' */
.icon-store:before { content: '\e855'; } /* '' */
.icon-suitcase:before { content: '\e856'; } /* '' */
.icon-target-1:before { content: '\e857'; } /* '' */
.icon-tasks:before { content: '\e858'; } /* '' */
.icon-telescope:before { content: '\e859'; } /* '' */
.icon-tie:before { content: '\e85a'; } /* '' */
.icon-ufo:before { content: '\e85b'; } /* '' */
.icon-website:before { content: '\e85c'; } /* '' */
.icon-basket:before { content: '\e85d'; } /* '' */
.icon-binoculars:before { content: '\e85e'; } /* '' */
.icon-calculator:before { content: '\e860'; } /* '' */
.icon-calendar-2:before { content: '\e861'; } /* '' */
.icon-clock-2:before { content: '\e862'; } /* '' */
.icon-briefcase-2:before { content: '\e885'; } /* '' */
.icon-broken-link:before { content: '\e886'; } /* '' */
.icon-calculator-1:before { content: '\e887'; } /* '' */
.icon-calculator-2:before { content: '\e888'; } /* '' */
.icon-calendar-3:before { content: '\e88b'; } /* '' */
.icon-calendar-5:before { content: '\e88e'; } /* '' */
.icon-compose:before { content: '\e8a2'; } /* '' */
.icon-diamond:before { content: '\e8b1'; } /* '' */
.icon-document-1:before { content: '\e8b8'; } /* '' */
.icon-edit:before { content: '\e8ba'; } /* '' */
.icon-file-1:before { content: '\e8c9'; } /* '' */
.icon-file-2:before { content: '\e8ca'; } /* '' */
.icon-garbage-2:before { content: '\e8ec'; } /* '' */
.icon-help:before { content: '\e8ee'; } /* '' */
.icon-house:before { content: '\e8f8'; } /* '' */
.icon-idea:before { content: '\e8ff'; } /* '' */
.icon-like:before { content: '\e908'; } /* '' */
.icon-like-1:before { content: '\e909'; } /* '' */
.icon-link:before { content: '\e90b'; } /* '' */
.icon-list-1:before { content: '\e90c'; } /* '' */
.icon-list-2:before { content: '\e90d'; } /* '' */
.icon-newspaper:before { content: '\e939'; } /* '' */
.icon-paper-plane-1:before { content: '\e946'; } /* '' */
.icon-photo-camera:before { content: '\e94d'; } /* '' */
.icon-placeholder-3:before { content: '\e957'; } /* '' */
.icon-push-pin:before { content: '\e960'; } /* '' */
.icon-search:before { content: '\e96c'; } /* '' */
.icon-settings-6:before { content: '\e979'; } /* '' */
.icon-star-1:before { content: '\e99d'; } /* '' */
.icon-success:before { content: '\e9a9'; } /* '' */
.icon-umbrella:before { content: '\e9b9'; } /* '' */
.icon-unlink:before { content: '\e9ba'; } /* '' */
.icon-users:before { content: '\e9c7'; } /* '' */
.icon-users-1:before { content: '\e9c8'; } /* '' */