/*
* 	Owl Carousel Owl Demo Theme 
*	v1.3.3
*/

.owl-theme .owl-controls {}


/* Styling Next and Prev buttons */

.owl-theme .owl-controls .owl-buttons div {
    color: #333;
    display: inline-block;
    zoom: 1;
    margin: 5px;
    padding: 8px 16px;
    font-size: 22px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background: #fff;
    filter: Alpha(Opacity=50);
    line-height: 1;
}


/* Clickable class fix problem with hover on touch devices */


/* Use it for non-touch hover action */

.owl-theme .owl-controls.clickable .owl-buttons div:hover {
    filter: Alpha(Opacity=100);
    /*IE7 fix*/
    opacity: 1;
    text-decoration: none;
}


/* Styling Pagination*/

.owl-theme .owl-controls .owl-page {
    display: inline-block;
    zoom: 1;
    *display: inline;
    /*IE7 life-saver */
}

.owl-theme .owl-controls .owl-page span {
    display: block;
    width: 10px;
    height: 10px;
    margin: 0px 0px 10px 10px;
    filter: Alpha(Opacity=50);
    /*IE7 fix*/
    opacity: 0.5;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    background: #de4526;
}

.owl-pagination {
    text-align: center;
    margin-top: 10px;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
    filter: Alpha(Opacity=100);
    /*IE7 fix*/
    opacity: 1;
}


/* If PaginationNumbers is true */

.owl-theme .owl-controls .owl-page span.owl-numbers {
    height: auto;
    width: auto;
    color: #FFF;
    padding: 2px 10px;
    font-size: 12px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}


/* preloading images */

.owl-item.loading {
    min-height: 150px;
    background: url(AjaxLoader.gif) no-repeat center center
}

@media screen and (max-width:1366px) {
    .owl-theme .owl-controls .owl-buttons div {
        color: #333;
        display: inline-block;
        zoom: 1;
        margin: 5px;
        padding: 2px 6px;
        font-size: 12px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        background: #fff;
        filter: Alpha(Opacity=50);
        line-height: 1;
    }
}

@media only screen and (max-width:768px) {
    .owl-theme .owl-controls .owl-buttons div {
        color: #333;
        display: inline-block;
        zoom: 1;
        margin: 5px;
        padding: 2px 6px;
        font-size: 12px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        background: #fff;
        filter: Alpha(Opacity=50);
        line-height: 1;
    }
}
